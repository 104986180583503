<template>
  <div class="misc-wrapper">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="imgLogo2"
          alt="Logo New X Coins"
          style="max-height: 3rem"
        />
      </b-link>
      <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("Você não está autorizado!") }} 🔐</h2>
        <p class="mb-2">
          {{ $t("Você não tem permissão para acessar esta página. Volte para a página inicial!") }}
        </p>
        <router-link to="/">
          <b-button variant="primary" class="mb-1 btn-sm-block"
            >{{ $t("Voltar para a página inicial") }}</b-button
          >
        </router-link>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from "bootstrap-vue";
import store from "@/store/index";
import ProjectLogo from "@/layouts/logo/Logo.vue";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    ProjectLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
  methods: {
    loginRoute() {
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
